import { FRICTION_REASON_FLOWS } from '~/constants/cart';
import { RefundInstructionsInfo, RefundStepsConfig } from '~/types/redemption';

export const REDEMPTION_DATA_API_URL = '/api/cart/redemption-data';
export const REDEEM_PRODUCT_API_URL = '/cart/redeem/success/';
export const REDEMPTION_SUPPORT_REQUEST_API_URL =
  '/api/helpscout/create_ticket/';
export const REDEMPTION_REFUND_API_URL = '/cart/refund/';
export const REDEMPTION_LICENSE_REFUND_API_URL = '/license/refund/';
export const EXTEND_LICENSE_ACTIVATION_TIME_API_URL =
  '/api/cart/extend-activation/';
export const ACTIVATE_LICENSE_API_URL = '/license/activate/';

export const DIGITAL_DOWNLOAD_DEAL_PLAN_NAME = 'Digital Download';

export const PENDING_ACTIVATION_LICENSE_KEY = 'Pending Activation';

export const REDEMPTION_REFUND_REASON_FEEDBACK_LENGTH = 200;
export const REDEMPTION_REFUND_REASON_ERROR_MESSAGE =
  'Failed to send refund reason';

export const REDEMPTION_COLORFUL_SHARE_EXPERIMENT = 'redemption-colorful-share'; // variants: [enabled, disabled]

export enum REDEMPTION_REFUND_STEPS {
  REASON = 'reason',
  FEEDBACK = 'feedback',
  SUPPORT = 'support',
  SUPPORT_RESULT = 'support-result',
  UPGRADE = 'upgrade',
  REFUND = 'refund',
  CANNOT_REFUND = 'cannot-refund',
  REFUND_COMPLETE = 'refund-complete',
}

export const REFUND_INSTRUCTIONS_INFO: Array<RefundInstructionsInfo> = [
  {
    title: 'If you paid via PayPal',
    description:
      'You should receive an email from PayPal letting you know of the refund. You can also check your PayPal account to see it.',
  },
  {
    title: 'If you paid via Credit/Debit Card',
    description:
      'Please allow 5-10 business days to post. (Note that refunds may appear in the form of a reversal. In the case of a reversal, the original payment will drop off of your bank statement, and a separate credit is not issued.)',
  },
  {
    title: 'If you choose to Refund via AppSumo Credits',
    description:
      'The refund amount will immediately be converted to credits. No waiting. The credits will appear in My Rewards section.',
  },
  {
    title: 'If you paid via AppSumo Credits',
    description:
      'Your credits will be added back to your AppSumo account and you will see them at checkout during your next purchase.',
  },
];

export const REDEMPTION_REFUND_STEPS_CONFIG: RefundStepsConfig = {
  [REDEMPTION_REFUND_STEPS.REASON]: {
    getTitle: () => 'Initiate refund',
    nextStep: ({ selectedReason }) => {
      if (selectedReason?.flow === FRICTION_REASON_FLOWS.OTHER) {
        return REDEMPTION_REFUND_STEPS.REFUND;
      }
      return REDEMPTION_REFUND_STEPS.FEEDBACK;
    },
    showBackButton: () => true,
    getBackButtonText: () => 'Cancel',
  },
  [REDEMPTION_REFUND_STEPS.FEEDBACK]: {
    getTitle: ({ selectedReason }) => {
      const isSupport = selectedReason?.flow === FRICTION_REASON_FLOWS.SUPPORT;
      return isSupport
        ? 'Product redemption issues?'
        : selectedReason?.title || 'Feedback';
    },
    nextStep: ({ selectedReason }) => {
      if (selectedReason?.flow === FRICTION_REASON_FLOWS.SUPPORT) {
        return REDEMPTION_REFUND_STEPS.SUPPORT;
      } else if (selectedReason?.flow === FRICTION_REASON_FLOWS.CHANGE_PLAN) {
        return undefined;
      }
      return REDEMPTION_REFUND_STEPS.REFUND;
    },
    showBackButton: () => true,
    getBackButtonText: ({ selectedReason }) => {
      if (selectedReason?.flow === FRICTION_REASON_FLOWS.SUPPORT) {
        return 'No, thanks';
      }
      return 'Go back';
    },
    getContinueButtonText: ({ selectedReason }) => {
      if (selectedReason.flow === FRICTION_REASON_FLOWS.SUPPORT) {
        return 'Yes, contact Support';
      } else if (selectedReason.flow === FRICTION_REASON_FLOWS.CHANGE_PLAN) {
        return 'Continue to plans';
      }
      return 'Continue';
    },
  },
  [REDEMPTION_REFUND_STEPS.SUPPORT]: {
    getTitle: () => 'Contact Support',
    nextStep: () => REDEMPTION_REFUND_STEPS.SUPPORT_RESULT,
    showBackButton: () => false,
    getContinueButtonText: () => 'Send to Support',
  },
  [REDEMPTION_REFUND_STEPS.SUPPORT_RESULT]: {
    getTitle: () => '',
    showBackButton: () => false,
    getContinueButtonText: () => 'Close',
  },
  [REDEMPTION_REFUND_STEPS.UPGRADE]: {
    getTitle: () => 'Upgrade',
    showBackButton: () => false,
  },
  [REDEMPTION_REFUND_STEPS.REFUND]: {
    getTitle: ({ isFreeLicenseTier, userCanRefund }) => {
      if (!userCanRefund) {
        return 'Quick update, Sumo-ling!';
      }
      return isFreeLicenseTier ? 'Delete free account' : 'Refund method';
    },
    nextStep: () => REDEMPTION_REFUND_STEPS.REFUND_COMPLETE,
    showBackButton: ({ userCanRefund }) => {
      // if user can refund show back button
      return !!userCanRefund;
    },
    getBackButtonText: () => 'Cancel',
    getContinueButtonText: ({ isFreeLicenseTier, userCanRefund }) => {
      if (!userCanRefund) {
        return 'Contact support';
      }
      return isFreeLicenseTier
        ? 'Confirm and delete account'
        : 'Confirm and initiate refund';
    },
  },
  [REDEMPTION_REFUND_STEPS.CANNOT_REFUND]: {
    getTitle: () => {
      return 'Quick update, Sumo-ling!';
    },
    showBackButton: () => true,
    getBackButtonText: () => 'Cancel',
    nextStep: () => REDEMPTION_REFUND_STEPS.SUPPORT,
    getContinueButtonText: () => {
      return 'Contact support';
    },
  },
  [REDEMPTION_REFUND_STEPS.REFUND_COMPLETE]: {
    getTitle: ({ isFreeLicenseTier, successfullyRefunded }) => {
      if (!successfullyRefunded) {
        return "Something's not right...";
      }
      return isFreeLicenseTier ? 'Account deleted' : 'Refund successful!';
    },
    showBackButton: () => false,
    getContinueButtonText: () => 'Close',
  },
};

export const LICENSE_ACTIVATION_REDIRECT_MILLISECONDS = 7000;
