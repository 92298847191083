import { memo } from 'react';
import Image from 'next/image';
import { Button, Heading } from '@appsumo/dorado-react';

import taco from '~/public/taco.svg';
import { LineItemAddFunction } from '~/lib/cart/useCart';
import { currency } from '~/lib/format';
import { Deal, Plan } from '~/types/deal';

import V2PricingTableBuyButton from './V2PricingTableBuyButton';

interface V2PricingTableHeaderProps {
  numPlansShowing: number;
  plans: Plan[];
  currPlanIndex: number;
  hasRecommendedPlan: boolean;
  deal: Deal;
  isInactiveIndefinitely: boolean;
  isLicensing: boolean;
  lineItemAdd: LineItemAddFunction;
  headerClasses: string;
}

export default memo(function V2PricingTableHeader({
  numPlansShowing,
  plans,
  currPlanIndex,
  hasRecommendedPlan,
  deal,
  isInactiveIndefinitely,
  isLicensing,
  lineItemAdd,
  headerClasses,
}: V2PricingTableHeaderProps) {
  return (
    <thead data-testid="pricing-table-header" className={headerClasses}>
      <tr>
        <th className="w-1/5 max-w-[1/5] border border-sundog p-4">
          <Heading.H4 className="mb-4 text-midnight">Need help?</Heading.H4>
          <Button className="block w-full" href="#questions" tertiary>
            Ask a question
          </Button>
        </th>
        {Array.from({ length: numPlansShowing }, (_, index) => {
          const currPlan = plans[currPlanIndex + index];

          return (
            <th key={index} className="w-1/5 max-w-[1/5] border border-sundog">
              {!!currPlan?.highlight && (
                <div className="bg-spruce px-6 py-2 text-center text-sm text-white">
                  <Image
                    src={taco}
                    alt="reocmmended plan"
                    className="mr-2 inline-block"
                  />
                  Picked for you
                </div>
              )}
              <div
                className={`w-full p-4 ${
                  currPlan?.highlight ? 'bg-iceberg' : ''
                } ${hasRecommendedPlan && !currPlan?.highlight ? 'mt-9' : ''}`}
              >
                <p
                  className="mb-2 font-semibold text-midnight"
                  style={{ fontFamily: 'var(--font-barlow)' }}
                >
                  {currPlan?.public_name}
                </p>
                <div className="mb-4 flex items-center justify-center gap-2">
                  <span className="text-2xl text-black-pearl">
                    {currency(currPlan?.price)}
                  </span>
                  <span className="font-normal text-grace line-through">
                    {currency(currPlan?.original_price)}
                  </span>
                </div>
                <V2PricingTableBuyButton
                  deal={deal}
                  dealInactive={isInactiveIndefinitely}
                  plan={currPlan}
                  isLicensing={isLicensing}
                  lineItemAdd={lineItemAdd}
                />
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
});
