import {
  ReactElement,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useWindowSize } from 'usehooks-ts';
import { Heading } from '@appsumo/dorado-react';

import ChevronRight from '~/public/font-awesome/chevron-right-bolt.png';

import { useCart } from '~/lib/cart';
import { useEvent } from '~/lib/events';
import { PDPContext } from '~/lib/product/context';
import { isDealInactiveIndefinitely } from '~/lib/product/utils';
import { DealFeature, Plan } from '~/types/deal';

import PricingTableRows from './PricingTableRows';
import V2MobilePricingTableHeader from './V2MobilePricingTableHeader';
import V2PricingTableHeader from './V2PricingTableHeader';

export const NUM_PLAN_COLS_MOBILE = 2;
export const NUM_PLAN_COLS_TABLET = 3;
export const NUM_PLAN_COLS_DESKTOP = 4;

export default memo(function V2PricingTable({
  plans,
  features,
}: {
  plans: Plan[];
  features: DealFeature[];
}): ReactElement | null {
  const { deal } = useContext(PDPContext);
  const tableRef = useRef<HTMLTableElement>(null);
  const router = useRouter();
  const { lineItemAdd } = useCart();
  const { width } = useWindowSize();
  const totalCols = plans.length;
  const [currPlanIndex, setCurrPlanIndex] = useState(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [numPlansShowing, setNumPlansShowing] = useState(
    Math.min(NUM_PLAN_COLS_DESKTOP, totalCols),
  );
  const [maxStartingIndex, setMaxStartingIndex] = useState(
    totalCols - numPlansShowing,
  );

  const isLicensing = !!deal?.use_licensing;

  useEffect(() => {
    if (width < 768) {
      setNumPlansShowing(NUM_PLAN_COLS_MOBILE); // note: for single tier deals we should be using the old pricing card/table so no need for math.min
    } else if (width < 1024) {
      setNumPlansShowing(Math.min(NUM_PLAN_COLS_TABLET, totalCols));
    } else {
      setNumPlansShowing(Math.min(NUM_PLAN_COLS_DESKTOP, totalCols));
    }
  }, [width, totalCols]);

  useEffect(() => {
    setMaxStartingIndex(totalCols - numPlansShowing);
  }, [totalCols, numPlansShowing]);

  useEffect(() => {
    if (currPlanIndex > maxStartingIndex) {
      setCurrPlanIndex(maxStartingIndex);
    }
  }, [currPlanIndex, maxStartingIndex]);

  useEvent('cart:added', () => {
    router.push('/cart');
  });

  useEvent('header:visible', (headerVisible: boolean) => {
    setIsHeaderVisible(headerVisible);
  });

  const isInactiveIndefinitely = useMemo(
    () => isDealInactiveIndefinitely(deal.stage_details),
    [deal.stage_details],
  );

  const hasRecommendedPlan = !!plans?.some((plan) => !!plan.highlight);
  const headerClasses = `hidden md:table-header-group sticky bg-white shadow-[inset_0_-1px_0_0_theme(colors.sundog)] transition-all duration-[500ms] ease-in-out ${
    isHeaderVisible ? 'top-[174px] md:top-[195px]' : 'top-[64px]'
  }`;

  if (!totalCols || !features?.length) return null;

  return (
    <div>
      <div className="mb-4 flex justify-between">
        <Heading.H3>Compare plans</Heading.H3>
        <div className="flex items-center gap-4">
          <Image
            data-testid="table-left-arrow"
            src={ChevronRight}
            alt="left arrow icon"
            height={16}
            className={`inline-block rotate-180 ${
              currPlanIndex === 0 ? 'grayscale' : 'cursor-pointer'
            }`}
            onClick={() => {
              setCurrPlanIndex(Math.max(0, currPlanIndex - numPlansShowing));
            }}
          />
          <span>
            Showing{' '}
            <span className="hidden md:inline-block">
              {isLicensing ? 'tiers' : 'codes'}
            </span>{' '}
            <span data-testid="qty-showing-text">
              {currPlanIndex + 1} - {currPlanIndex + numPlansShowing} of{' '}
              {totalCols}
            </span>
          </span>
          <Image
            data-testid="table-right-arrow"
            src={ChevronRight}
            alt="right arrow icon"
            height={16}
            className={`inline-block ${
              currPlanIndex === maxStartingIndex
                ? 'grayscale'
                : 'cursor-pointer'
            }`}
            onClick={() => {
              setCurrPlanIndex(
                Math.min(currPlanIndex + numPlansShowing, maxStartingIndex),
              );
            }}
          />
        </div>
      </div>
      <V2MobilePricingTableHeader
        plans={plans}
        currPlanIndex={currPlanIndex}
        hasRecommendedPlan={hasRecommendedPlan}
        deal={deal}
        isInactiveIndefinitely={isInactiveIndefinitely}
        isLicensing={isLicensing}
        lineItemAdd={lineItemAdd}
      />
      <table
        className={`-ml-4 w-[100vw] max-w-[100vw] border-collapse border border-sundog md:ml-0 md:w-full md:max-w-none`}
        ref={tableRef}
      >
        <V2PricingTableHeader
          numPlansShowing={numPlansShowing}
          plans={plans}
          currPlanIndex={currPlanIndex}
          hasRecommendedPlan={hasRecommendedPlan}
          deal={deal}
          isInactiveIndefinitely={isInactiveIndefinitely}
          isLicensing={isLicensing}
          lineItemAdd={lineItemAdd}
          headerClasses={`${headerClasses}`}
        />
        <tbody>
          <PricingTableRows
            features={features}
            plans={plans}
            currPlanIndex={currPlanIndex}
            numPlansShowing={numPlansShowing}
          />
        </tbody>
      </table>
    </div>
  );
});
