import { memo } from 'react';
import Image from 'next/image';

import { Popover } from '~/components/ui/Popover';
import { FeatureType, PRICING_CELL_STYLE } from '~/constants/product';
import { Plan } from '~/types/deal';
import { DealFeature } from '~/types/deal';
import CheckDollar from '~/public/font-awesome/check-dollar.png';
import RedX from '~/public/font-awesome/red-x.png';
import question from '~/public/question-mark.svg';

export default memo(function PricingTableRows({
  features,
  plans,
  currPlanIndex,
  numPlansShowing,
}: {
  features: DealFeature[];
  plans: Plan[];
  currPlanIndex: number;
  numPlansShowing: number;
}) {
  const rows = Array.from({ length: features.length }, (_, i) => {
    const currFeature = features[i];
    const featureEntries = Array(numPlansShowing).fill(<></>);
    const visiblePlans = plans.slice(
      currPlanIndex,
      currPlanIndex + numPlansShowing,
    );

    visiblePlans.forEach((currPlan, j) => {
      currFeature?.entries?.forEach((entry) => {
        if (!!entry?.plan_id && entry?.plan_id === currPlan?.id) {
          const featureType = currFeature?.feature_type;
          const entryValue = entry?.entry;

          featureEntries[j] = (
            <td
              key={`cell-${i}-${j}`}
              className={`${PRICING_CELL_STYLE} ${
                currPlan?.highlight ? 'bg-iceberg' : ''
              }`}
            >
              {featureType !== FeatureType.BOOLEAN ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: entryValue ? (entryValue as string) : '-',
                  }}
                />
              ) : (
                <Image
                  src={entryValue ? CheckDollar : RedX}
                  alt={`${entryValue ? 'green checkmark' : 'red x'} icon`}
                  height={16}
                  className="inline-block"
                />
              )}
            </td>
          );
        }
      });
    });

    return (
      <tr key={`row-${i}`}>
        <td className={`${PRICING_CELL_STYLE}`}>
          <div className="inline-flex items-center gap-2">
            <div
              className="grow"
              dangerouslySetInnerHTML={{ __html: features[i]?.feature }}
            />
            {!!features[i]?.tooltip && (
              <Popover
                content={features[i].tooltip as string}
                className="border-forest bg-forest text-white"
              >
                <div className="my-auto h-4 w-4 shrink-0">
                  <Image
                    src={question}
                    height="16"
                    width="16"
                    alt="question mark icon"
                  />
                </div>
              </Popover>
            )}
          </div>
        </td>
        {featureEntries}
      </tr>
    );
  });

  return <>{rows}</>;
});
