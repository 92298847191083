import { memo } from 'react';
import Image from 'next/image';

import { LineItemAddFunction } from '~/lib/cart/useCart';
import { currency } from '~/lib/format';
import taco from '~/public/taco.svg';
import { Deal, Plan } from '~/types/deal';

import { NUM_PLAN_COLS_MOBILE } from './V2PricingTable';
import V2PricingTableBuyButton from './V2PricingTableBuyButton';

interface MobilePricingTableHeaderProps {
  plans: Plan[];
  currPlanIndex: number;
  hasRecommendedPlan: boolean;
  deal: Deal;
  isInactiveIndefinitely: boolean;
  isLicensing: boolean;
  lineItemAdd: LineItemAddFunction;
}

export default memo(function V2MobilePricingTableHeader({
  plans,
  currPlanIndex,
  hasRecommendedPlan,
  deal,
  isInactiveIndefinitely,
  isLicensing,
  lineItemAdd,
}: MobilePricingTableHeaderProps) {
  return (
    <div
      className={`-ml-4 w-[100vw] max-w-[100vw] border border-b-0 border-sundog md:hidden`}
    >
      <div className="flex justify-center">
        {Array.from({ length: NUM_PLAN_COLS_MOBILE }, (_, index) => {
          const currPlan = plans[currPlanIndex + index];

          return (
            <div
              key={`col-header-${index}`}
              className={`w-1/2 text-center ${
                index === 0 && 'border-r border-r-sundog'
              }`}
            >
              {!!currPlan?.highlight && (
                <div className="bg-spruce px-6 py-2 text-center text-sm font-bold text-white">
                  <Image
                    src={taco}
                    alt="recommended plan"
                    className="mr-2 inline-block"
                  />
                  Picked for you
                </div>
              )}
              <div
                className={`w-full p-4 ${
                  currPlan?.highlight ? 'bg-iceberg' : ''
                } ${hasRecommendedPlan && !currPlan?.highlight ? 'mt-9' : ''}`}
              >
                <p
                  className="mb-2 font-semibold text-midnight"
                  style={{ fontFamily: 'var(--font-barlow)' }}
                >
                  {currPlan?.public_name}
                </p>
                <div className="mb-4 flex items-center justify-center gap-2">
                  <span className="text-2xl text-black-pearl">
                    {currency(currPlan?.price)}
                  </span>
                  <span className="font-normal text-grace line-through">
                    {currency(currPlan?.original_price)}
                  </span>
                </div>
                <V2PricingTableBuyButton
                  deal={deal}
                  dealInactive={isInactiveIndefinitely}
                  plan={currPlan}
                  isLicensing={isLicensing}
                  lineItemAdd={lineItemAdd}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
