import { useCallback, memo } from 'react';
import { Button } from '@appsumo/dorado-react';

import useUser from '~/lib/user';
import { useEventDispatch } from '~/lib/events';
import { LineItemAddFunction } from '~/lib/cart/useCart';
import { COMING_SOON_BANNER_TEXT } from '~/lib/campaigns/constants';
import { Deal } from '~/types/deal';
import type { PlanProps } from '~/types/product';

export default memo(function V2PricingTableBuyButton({
  deal,
  dealInactive,
  plan,
  isLicensing,
  lineItemAdd,
}: {
  deal: Deal;
  dealInactive: boolean;
  plan: PlanProps;
  isLicensing: boolean;
  lineItemAdd: LineItemAddFunction;
}) {
  const dispatchEvent = useEventDispatch();
  const { user } = useUser();

  const isEarlyAccessOrLastCall =
    deal.buy_button.is_early_access || deal.buy_button.is_last_call;
  const isComingSoon =
    !isEarlyAccessOrLastCall &&
    (deal.banner_details?.text === COMING_SOON_BANNER_TEXT ||
      deal.buy_button?.is_coming_soon);

  const onBuyClick = useCallback(() => {
    if (isEarlyAccessOrLastCall && !user?.has_plus) {
      dispatchEvent('plan:updatePricingCard', {
        label: isLicensing ? plan?.public_name : `${plan?.codes} Codes`,
        value: plan.id,
      });
      dispatchEvent('pdp:showPlusShelf');
    } else {
      lineItemAdd(deal.id, plan.id, '', isLicensing ? 1 : plan.codes, false);
    }
  }, [
    isEarlyAccessOrLastCall,
    user,
    dispatchEvent,
    lineItemAdd,
    deal?.id,
    plan?.id,
    plan?.codes,
    plan?.public_name,
    isLicensing,
  ]);

  if (!plan || !deal) return <></>;

  return (
    <Button
      className="block w-full whitespace-nowrap rounded-full text-center"
      onClick={onBuyClick}
      disabled={isComingSoon || dealInactive}
    >
      {isComingSoon
        ? 'Coming soon'
        : dealInactive
        ? 'Sold out'
        : plan.is_free
        ? 'Get FREE'
        : 'Buy now'}
    </Button>
  );
});
